.breadCrumb{
	list-style:none;
	@include zeroMargPad;
	display: none;
	position: fixed;
	margin: 0;
	float: none;
	bottom: 0;
	z-index: 3;
	background: $primary;
	right: 1.25%;
	@media all and (max-width: $md){
		
	}
	@media all and (max-width: $sm){
		
	}
	@media all and (max-width: $xs){
		
	}
	@media all and (max-width: $tn){
		
	}
	li{
		position:relative;
		padding:10px 15px;
		margin:0;
		@include headerFont($weight:600);
		font-size:1em;
		float:left;
		&.menuLink{
			background:$secondary!important;
			color:$light;
			cursor:pointer;
			@include transition;
			@media all and (max-width: $xs){
				
			}
			span{
				font-size:2.5em;
			}
		}
		&.menuLink:hover{
			background:$primary!important;
		}
		
	}
	progress{
        display: block;
		margin: 5px -20px 0 -20px;
		height: 10px;
		position: absolute;
		width: 100%;
		@media all and (max-width: $xs){
			left:0;
			bottom:0;
			padding:0;
			margin: 0;
		}
    }
}
.login .breadCrumb{
	display:none;
}


@media all and (max-width: $xs + 100){
    .breadCrumb{
        bottom: auto;
        left: 1.25%;
        right: auto;
        z-index: 10;
        top: 5px;
        li{
            padding: 5px 12px;
            &.menuLink{
                background: transparent !important;
                &:hover{
                    background: $secondary !important;
                }
            }
        }
        
    }
}