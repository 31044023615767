#learnMoreWrapper {
    display: none; 
    z-index:99;
    position: fixed;
    bottom:0;
    left: 1.25%;
    width:100%;
    @media all and (max-width: 1700px){
        
    }
    @media all and (max-width: $xs){
        
    }
    &:hover{
        .icon{
            
        }
    } 
    &.selected{
        .icon{
            background-color: $primary;
            color: $secondary;
            &:before{
                content: 'h';
            }
        }
        /* .toolTip{
            color:$secondary;
            font-family:$secondaryFont;
            border:3px solid $secondary;
            background:$primary;
            p{
                color:$secondary;
            }
        } */
    }
    .icon {
        float:left;
        color: $primary;
        background:$primary;
        padding:15px 20px;
        cursor:pointer;
        @include transition;
        &:before{
            @include pseudoContent;
            font-family: 'st-johns';
            content: 'g';
            font-size: 1.8em;
            text-align:center;
            left:0;
            color:$light;
        }
        &.hover{
            background:$secondary;
        }
        @media all and (max-width: $xs){
            display:inline!important;
            margin-left:5px;
        }
    }
}




.toolTip{
    font-size: 1em;
    color:$primary;
    font-family:$primaryFont;
    padding:20px;
    background:$light;
    width:auto;
    border:3px solid $primary;
    display:none;
    position: absolute;
    top:0px;
    left:60px;
    p{
    margin: 0px;
    }
    @media all and (max-width: $xs){
        overflow: hidden;
        position: static;
        text-align: right;
        width: auto;
        font-size:.6em;
        padding:0 5px 5px 5px;
        margin:0;
        line-height:1.2em;
        
    }
    p {
        @media all and (max-width: $xs){
            font-size:.85em!important;
            text-align:right;
        }
    }
    .notch{
        @include bottomArrow($color:$primary,$top:100%,$left:30px);
    }
}

.learnMoreLinks{
	list-style:none;
	margin:0;
	padding:0;   
    span {text-transform: uppercase;}
    li{
        padding:5px!important;
        margin:0!important;
        &:before{
            display:none!important;
        }
            a {
            display:block;
            padding:10px 50px;
            background-color:$secondary;
            color:$light;
            text-decoration:none;
            font-size:1.35em;
            @include transition;
            &:hover {
                background-color:$primary;
                color:$light;
                &:before{
                    content:"c";
                    color:$light;
                }
            }
            &:before{
                content:"i";
                font-family: 'st-johns';
                float:left;
                display:block;
                margin-right:15px;
                margin-left:-35px;
                color:$primary;
            }
        }
    }
}
.slide.learnMore{
    .textCopy{
        width:100%!important;
    }
}
#offcanvas-links{
    position: fixed;
    background: $primary;
    height: 100%;
    width:100%;
    padding: 20px 20px 120px 20px;
    left: -100%;
    top: 0;
    overflow-y:auto;
    //@include transition;
    list-style:none;
    z-index:2;
    &.inSection{
        li{
            display:none;
        }
        .homeButton{
            display:block;
        }
    }
}


@media all and (max-width: $lg) { //1091
    #learnMoreWrapper {
        .icon{
            padding: 15px;
        }
    }
}

@media all and (max-width: $xs + 100){
    
    #learnMoreWrapper {
        bottom: 75px;
        left: -1.25%;
        .icon{
            padding: 5px 10px;
        }
    }
    
    
}