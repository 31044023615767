.masterQuiz,.surveySlide{
    #learnMoreWrapper{
        display: none !important;
        opacity: 0 !important;
    }
	h3{
		display:block;
	}
	.questionBlock{
		@include clearfix;
		border-bottom:2px solid $primary;
		margin:15px 0;
		@include transition($speed:.4s);
		background:$light;
		&:hover{
			
		}
	}
	.question{
		h3{
			padding:15px;
			margin:0;
			border:0!important;
			line-height: 1.25em;
			span{
				display: block;
				float: left;
				padding: 15px;
				color: darken($accent,10%);
				margin: -15px 10px 0 -15px;
			}
		}
	}
	.answer,.Answer{
		div{
			color:$basic;
			margin:0 0 0 0!important;
			padding:8px 16px;
			@include transition($speed:.4s);
			cursor:pointer;
			&:nth-child(1){
				background:lighten($primary,15%);
			}
			&:nth-child(2){
				background:lighten($primary,10%);
			}
			&:nth-child(3){
				background:lighten($primary,5%);
			}
			&:nth-child(4){
				background:$primary;
			}
			&:nth-child(5){
				background:darken($primary,5%);
			}
			&:nth-child(6){
				background:darken($primary,10%);
			}
			&:nth-child(7){
				background:darken($primary,15%);
			}
			&:nth-child(8){
				background:darken($primary,20%);
			}
			&:nth-child(9){
				background:darken($primary,25%);
			}
			&:nth-child(10){
				background:darken($primary,30%);
			}
			&:hover{
				 background:$secondary!important; 
				 span{
				 	color:$light;
				 }
			}
			&.selected{
				background:darken($accent,10%);
			}
			input{
				float:none;
				margin:0 5px 0 0!important;
			}
			textarea{
				color:$basic;
			}
			span{
				margin-left: 20px;
				display: block;
				margin-top: -26px;
			}
		}
	}
	#dc4{
		background:darken($primary,10%);
		padding:10px;
		color:$light;
		@include transition($speed:.4s);
		display:block!important;
		@include opacity(100,1);
		visibility: visible !important;
		&.hidden{
			@include opacity(0,0);
			visibility: visible !important;
			display:block!important;
			height:0;
			overflow:hidden;
			padding:0;
			textarea{
				height:0;
			}
		}
		textarea{
			width:100%;
			height:100px;
			color:$basic;
		}
	}
	#dc5{
		background:lighten($primary,10%);
		padding:10px;
		color:$light;
		@include transition($speed:.4s);
		display:block!important;
		@include opacity(100,1);
		visibility: visible !important;
		&.hidden{
			@include opacity(0,0);
			visibility: visible !important;
			display:block!important;
			height:0;
			overflow:hidden;
			padding:0;
			textarea{
				height:0;
			}
		}
		textarea{
			width:100%;
			height:100px;
			color:$basic;
		}
	}
	&.masterQuiz{
		&.graded{
			.gradedMessage{
				display:block;
			}
			.retakeMessage{
				display:none;
			}
			.backToMenuBtn{
				cursor: pointer ;
				margin: 0 auto 10px auto;
				text-align: center;
				display: block;
				@include rounded(0);
				padding:10px 25px;
				background:$primary;
				color:$light;
				text-transform:uppercase;
				font-size:1.125em;
		 		border: 1px solid white;
		 		float:right;
		 		@include transition;
		 		&:hover{
		 			background:lighten($primary,10%);
		 		}
			}
			.questionBlock{
				display:none!important;
				&.review{
					display:block!important;
				}
				input{
					display:none;
				}
				.wrong{
					color: $primary!important;
					&:before{
						font-family: 'Glyphicons Halflings';
						content: "\e014";
						color: $primary;
						float: left;
						margin: -5px 0 0 -15px;
						font-size: 1.5em;
						background: $light;
						padding: 5px;
						@include rounded(50%);
					}
					&:hover{
						color: $primary!important;
					}
				}
				.answer{
					div{
						cursor: default;
						&:hover{
							 background:transparent!important; 
							 span{
							 	color:$basic;
							 }
						}
						span{
							margin-top:0;
						}
					}
				}
			}
			#survey-submit.btn,#quiz-submit{
				display:none;
			}
		}
		&.completed{
			.questionBlock,.reviewText{
				display:none;
			}
		}
		.gradedMessage{
			display:none;
		}
		.answer,.Answer{
			div{
				float:none;
				width:100%;
				text-align:left;
				background:transparent;
				color:$basic;
				border-bottom:2px solid $light;
				/* &:hover{
					background:lighten($secondary,10%);
					color:darken($primary,35%);
				} */
				&.selected{
					background:darken($accent,10%);
					/* &:hover{
						color:$light;
					} */
				}
			}
		}
	}
	&.surveySlide{
		.questionBlock{
			.answer{
				div{
					color:$light!important;
				}
			}
		}
	}
	#survey-submit.btn,#quiz-submit{
		margin: 10px auto;
		text-align: center;
		display: block;
		@include rounded(0);
		padding:10px 15px;
		background:$secondary;
		color:$light;
        &:hover{
            background: $primary
        }
	}
	.textCopy{
		width:100%!important;
	}
}
.ie8{
	
}

@media (max-width: $xs + 100){
    .slide.masterQuizSlide{
       padding:  0 2% 40px 2% !important;  
        .questionBlock{
            h3{
                font-size: 1.1em;
            }
        }
    }
    
}