.acntMenuBtn{
    position: absolute;
    top:4px!important;
    right:1.25%;
    @include transition($speed:.5s);
    cursor:pointer;
    z-index:5;
    background:$primary;
    &:hover{
        background:$secondary;
        .icon-menu,.icon-close{
            color:$light;
        }
    }
    &.open{
    	right:450px;
    	top:0!important;
    	background:$primary;
    	&:hover{
    		.icon-close{
	            color:darken($primary,20%);
	        }
    	}
        .icon-menu{
            display:none;
        }
        .icon-close{
            display:block;
        }
        .alerts{
        	color:$primary;
        }
    }
    .icon-menu,.icon-close{
        display:block;
        color:$light;
        padding:15px 15px 25px 15px;
        border:2px solid $primary;
        font-size:2em;
        line-height:0;
        @include transition;
    }
    .icon-close{
    	padding:15px 15px 25px 15px;
    	color:$light;
        display:none;
        font-size:1.75em;
    }
    .alerts{
        display:block;
        text-transform:uppercase;
        background:$primary;
        color:$light;
        font-size:.80em;
        text-align:center;
        font-family:$primaryFont;
    }
}
#actnWindow{
	position: fixed;
	top:0;
	right:-450px;
	width:450px;
	height:100%;
	background:$primary;
	z-index:4;
	overflow:auto;
	@include transition($speed:.5s);
	&.open{
		right:0;
		footer{
			display:block;
		}
	}
	h2{
		color:$light;
		font-size: 1.5em;
    	padding: 20px 20px 60px 20px;
    	margin-right:90px;
		span{
			font-weight:600;
		}
	}
	.linksWindow{
		width:100%;
		height:100%;
		background:$light;
		clear:both;
		img{
			display:inline!important;
		}
		a{
			@include button;
		}
		p{
			padding: 10px 20px;
		}
		.slide{
			padding:0!important;
			margin-top:0!important;
			#learnMoreWrapper{
		        display:none!important;
		    }
		    h1{
		        display:none!important;
		    }
            h2{
                display: none;
            }
		    .hideOnHome{
				display:none;
			}
		    .learnMoreLinks{
		    	list-style: none;
				padding-left: 21px;
		    	li{
		    		padding:10px 20px;
					background:darken($light,10%);
					margin:3px 0;
					color:$primary;
					font-weight:bold;
		    		padding:0!important;
		    		background:transparent;
		    		a{
		    			background:$primary;
		    			margin-bottom:5px;
		    			padding:8px;
		    			font-size:1em;
		    			line-height:1.3em;
		    			&:hover{
		    				background:$secondary;
		    			}
		    			&:before{
		    				content:'';
		    			}
		    			span{
		    				text-transform:none;
		    				font-size:1em;
		    			}
		    		}
		    	}
		    }
		}
	}
	.tab{
		display:none;
		padding:5px 10px;
		color:$basic;
		float:left;
		margin-left:20px;
		background:$light;
		text-transform:uppercase;
	}
	footer{
		display:none;
		position: fixed;
		top:16px;
		right:20px;
	}
	a{
		@include button($color:darken($primary,10%));
		display:block;
		text-transform:uppercase;
		text-decoration:none;
	}
}
#actnWindowBlock{
	position: fixed;
	top:0;
	right:0;
	width:0;
	@include transition($speed:.75s);
	z-index:3;
	&.open{
		width:100%;
		height:100%;
		@include transparentBG($secondary,0.80);
	}
}

.orientationComplete{
    #actnWindow {
        h2{
            padding: 20px 20px 30px;
        }
        .tab{
            display: block;
        }
    }
}
@media all and (max-width: $xs + 100){
    .acntMenuBtn{
        position: fixed;
        &.open{
            z-index: 200;
            right: 20px
        }
    }
    #actnWindow{
        &.open{
            width: 100%;
            footer{
                top: 65px;
            }
        }

        
    }
}