/**VARIABLES**/


//Colors
$primary: #cf102d; //red
$secondary: #051c2c; //dark blue
$accent: #898B8E; //grey
$basic: #000;  //Black
$light: #fff;    //White
$dark: #615f5f;    //Grey
$grey: #d7e0e6;
$link: $primary; 
$hover: lighten($primary,10%); 
$bg: $light; //LTGrey
$wrong: #6d0b1a; //Red
$correct: #047d07; 
//Media Queary Resolutons
$tn : 500px;
$xs : 767px;
$sm : 991px;
$md : 1200px;
$lg : 1500px;
$max : 100%;

//Fonts
$primaryFont: 'proxima-nova-alt', sans-serif;
$secondaryFont:'minion-pro', serif;
$basicFont: 'Arial', Geneva, sans-serif;
$alternateFont: 'Arial', Geneva, sans-serif;
$iconFont: 'st-johns';

//Elements
$cursor: pointer;

