#validate{
	@include transparentBG($color:$basic,$opacity:0.85);
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index:9999;
	p{
		display:none;
		position: absolute;
		top:40%;
		left:0;
		width:100%;
		text-align:center;
		@include headerFont;
		color:$light;
		font-size:1.5em;
		padding:10px;
		em{
			font-size:1.75em;
			font-style:normal;
			font-family:$secondaryFont;
			h2{
				font-size:1em;
				color:$primary;
				text-transform:uppercase;
				display:inline;
			}
		}
		@media all and (max-width: $xs){
            font-size:1.5em;
        }
	}
}