#umbracoPreviewBadge{
    position: fixed!important;
    top: 0px !important;
    left:auto!important;
    width:100%!important;
    height:auto!important;
    background:#bacb0f!important;
    display:block!important;
    padding:0 4px 5px 4px;
    color:black;
    text-align:center;
    max-height:96px;
    overflow:auto;
//    &:before{
//        display:inline-block;
//        font-size:1.125em;
//        font-weight:bold;
//        text-transform:uppercase;
//        content:"PREVIEW MODE";
//        background:black;
//        color:#bacb0f;
//        padding:8px 15px;
//    }
    &:after{
        display:block;
        font-size:1em;
        margin-top:5px;
        content:"PREVIEW MODE: This is a preview, and should be used for content evaluation only. Some features and styles may not act/look exactly the way they do on live site."
    }
    &:hover{
        background:lighten(#bacb0f,10%)!important;
    }
//    &:first-of-type{
//        display:none!important;
//    }
    /* span{
        display:block!important;
    } */
}

.PreviewMode {
  .slide {
    display: none !important;
  }
  .slideHolder {
    top: 165px !important;
    .slide {
      display: block !important;
      .quizContent h1 {
        margin: -62px 0 60px 0 !important;
      }
    }
  }
  .logoWrapper.inside.top {
     bottom: -201px !important;
   }
  .progress {
    top: 65px !important;
  }
  .progressSectionTitle {
    top: 72px !important;
  }
  .answersWrapper {
    padding: 0px 35px 20px 35px !important;
  }
  nav.top {
    bottom: 0px !important;
  }
  #wrapper {
    display: none !important;
    &.forPreview {
      display: block !important;
    }
  }
  .acntMenuBtn {
    top: 40px !important;
  }
}

@media (max-width: $md) {
  .PreviewMode {
    .acntMenuBtn {
      top: 5px !important;
    }
  }
  #umbracoPreviewBadge {
    display: none !important;
  }
  #inner-content .logoWrapper.inside {
    bottom: -170px !important;
  }
}
@media (max-width: $sm) {
  .PreviewMode {
    .slideHolder {
      top: 90px !important;
      .slide {
        display: block !important;
        .quizContent h1 {
          display: none !important;
        }
      }
    }
    .progress {
      top: 75px !important;
    }
    .progressSectionTitle {
      top: 77px !important;
    }
    nav.top {
      display: none;
    }
  }
}
