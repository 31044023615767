@mixin button($color:$primary){
	background:$color;
	color:$light;
	padding:5px 10px;
	&:hover{
		background:darken($color,10%);
		text-decoration:none;
	}
    &:focus{
        background: $secondary;
    }
}
@mixin imgResponsive{
	display: block;
	max-width: 100%!important;
	height: auto!important;
}


@mixin iconFont{
    font-family: $iconFont !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
}

//ADA STYLE MIXINS
@mixin adaBtns {
	text-align:center;
	color:$light;
	background-color:$secondary;
	padding: 5px 15px;
	margin:10px auto;
	font-family: $basicFont;
	text-decoration:none;
	font-weight: bold;
    &:focus, &:hover{
        background: $primary;
    }
}
@mixin adaBtnHover {
	text-decoration: underline;
}
@mixin adaSchoolLogo {
	float:left;
	margin:8px 0 0 0;
	padding:0;
	width:310px;
	height:100px;
	background:url(../images/St-Johns-H.png) no-repeat;
	text-indent:-9999px;
}
@mixin adaHeaderFont {
    color: $secondary; 
    font-family: $primaryFont;
}