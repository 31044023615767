#inner-content {   
    @include clearfix;
    margin: 0;
    position:relative;
    display: none;
    height: 100%!important;
    .slideHolder{   
        @include absolute;
        @include clearfix;
        top:125px;
        width:100%;
        z-index: 3;
        overflow:visible;
        left: auto;
        display:block;
        padding:0 1.25% 28px 1.25%!important;
        @media all and (max-width: $md){
            
        }
        @media all and (max-width: $sm){
           
        }
        @media all and (max-width: $xs){
           
        }
    }
    .logoWrapper.inside{
        position: absolute;
        background:$primary;
        text-align:center;
        z-index:4;
        width:170px;
        height:170px;
        padding:20px 25px;
        left:auto;
        right:7%;
        bottom:0;
        @include transition($speed:.5s);
        &.top{
            bottom:-170px;
        }
    }
}



.slide {   
    position:relative;
    margin:0 auto;
    min-height:100%;
    margin-top:30px;
    padding:0 6% 40px 6%!important;
    @include clearfix;
    max-width:$max;
    background:$light;
    @media all and (max-width: $sm){
        padding:0 10% 40px 10%!important;
    }
//    @media all and (max-width: $xs){
//        padding:0 20px 20px 20px!important;
//        margin-top:0;
//    }
    @media all and (max-width: $tn){
        padding:0!important;
    }
    &.full{
        .textCopy{
            width:100%!important;
        }
    }
    .row{
        padding:0;
    }
.slideTip{
    padding:15px;
    color:$basic;
    border-top: 4px solid $primary;
    border-bottom: 4px solid $primary;
    position:relative;
    margin: 25px 0 30px 0;
    h3{
        margin: 0;
        color: $primary;
        padding: 10px 10px 0 10px;
        font-size: 1.5em;
        @include clearfix;
    }
    span.glyphicon{
        position: absolute;
        top: -15px;
        left: -15px;
        border-radius: 50%;
        font-size: 1.5em;
        padding:10px;
        background:$primary;
        color:$light;
    }
    .tip{
        padding:0 10px;
    }
    .icon-bubble{
        font-size: 2.125em;
        position: absolute;
        top: -6px;
        left: -11px;
        padding: 10px;
        color:$primary;
    }
}
.imgRight, .imgRight img{
    float:right;
    margin:0 0 10px 10px;
    padding:6px;
    position: relative;
    z-index:2;
    background:$light;
    @include boxShadow($blur:15px,$spread:0,$color:lighten($basic,20%));
    @include rspImage;
}
.imgLeft, .imgLeft img{
    float:right;
    margin:10px 10px 10px 0;
    @include rspImage;
}
a{
    padding:1px 5px;
    font-weight:normal;
    color:$primary;
    text-decoration:underline;
    display:inline-block;
    &:hover{
        color:$light;
        background:$primary;
        text-decoration:none;
    }
}
strong{
    font-weight:bold;

}
.slideHeading{
    float:none;
    padding:0 15px 0 0;
    width:275px;
    background-repeat:no-repeat;
    background-position:100% 100%;
    font-size:1em;

    span.more{
        font-family: $secondaryFont;
        background:url(../images/temp/learn-more-arrows.png) no-repeat 50% 50% $dark;
        width:270px;
        text-align:center;
        text-transform:uppercase;
        padding:5px 0;
        font-size:14px;
        color: $light;
        display:table;
        cursor: $cursor;
        
        &:hover{background-color:$secondary;}
    }
}
h1 {
    @include headerFont($weight:400);
    font-size:2em;
    color: $light;
    padding: 0;
    margin:-60px 0 60px 0;
}
h2 {
    font-family:$primaryFont;
    font-size:1.75em;
    margin:15px 0 15px 0;
    color: $primary;
}
h3{
    color: $secondary;
    font-family: $primaryFont;
    font-size: 1.45em;
    margin:20px 0 10px 0;
    font-weight: 600;
}
h4 { 
    
}
.red{
    color:$primary;
    font-weight:normal;
}
.inlineHeading{
    color:$primary;
    font-size:1.75em;
    font-family:$primaryFont;
}
.inlineSubheading{
    color:$secondary;
    font-size:1.45em;
    font-weight:600;
    font-family:$primaryFont;
}
.highlightBlock{
    display:block;
    border-left:3px solid $accent;
    border-right:3px solid $accent;
    background:lighten($accent,35%);
    color:$basic;
    padding:8px 12px;
    margin: 25px 0 30px 0;
}
.blueHighlightBlock{
    display:block;
    border-left:2px solid $secondary;
    border-right:2px solid $secondary;
    color:$secondary;
    padding:8px 12px;
}
.imgWrapper{
    padding:0 5px 0 0;
    @media all and (max-width: $sm){ 
        padding-right:0;
        margin:5px 0 0 0;
    }
    img{
        @include transparentBG($color:$light, $opacity:0.90);
        padding:20px;
        margin-bottom:5px;
    }
}

.row.textWrapper{

}
.slideSide{
    margin: 0;
    padding:50px 0 0 25px;
    @media all and (min-width: $lg){
        width:55%!important;
    }
    @media all and (max-width: $sm){
        margin-top:-20px;
        border-top:0;
    }
    &.selfDirSlideSide{
        float:right;
        padding-top:0!important;
    }
    img{
        @include imgResponsive;
        width:100%;
        float:right;
        margin-bottom:20px;
    }
    &.videoWrapper{
        img{
            margin:20px 0 0 0;
        }
        @media all and (max-width: $sm){
            /* margin:0;
            border-bottom:0;
            border-top:25px solid $light; */
        }
    }
}
.videoWrapper{
    float:right;
    margin:0;
    @media all and (max-width: $xs){
        float:none;
        margin:0 auto;
    }
}
.videoCopy, .textCopy {
    @include clearfix;
    float:left!important;
    color: $basic;
    font-size:1.125em;
    line-height:1.65em;
    background:$light;
    padding:0 40px 0 0 !important;
    @media all and (min-width: $lg){
        width:45%!important;
    }
    @media all and (max-width: $xs){
        padding:0!important;
    }
    @media all and (max-width: $tn){
       
    }
    p {
        margin:12px 0;
    }
    img{
        display:inline!important;
    }           
    ul{
        padding-left: 0;
        margin-left: 0;
        list-style:none;
        li {
            padding: 5px 0;
            margin-left:20px;
            &:before{
                content:"";
                width: 8px;
                height:8px;
                margin: 8px 12px 0 -20px;
                background: $primary;
                display:block;
                float:left;
            }
            li{
                &:before{
                    font-family: "st-johns";
                    content:"c";
                    width:auto;
                    height:auto;
                    font-size: .75em;
                    color: $secondary;
                    margin: 0px 12px 0 -24px;
                    background:transparent;
                }
                li{
                    &:before{
                        content:"";
                        width: 3px;
                        height:15px;
                        margin: 5px 1px 0 -19px;
                        background: $primary;
                        display:block;
                        float:left;
                        @include rounded(0);
                    }
                }
            }
            ol{
                padding-left:0;
                li{
                    &:before{
                        content:"";
                    }
                }
            }
        }
    }
    ol{
        padding-left:21px;
        li{
            padding: 5px 0;
        }
    }
    img{
        @include imgResponsive;
    }
}
.videoCopy,.textCopy{
    @media all and (max-width: $xs){
        margin-bottom:50px;
    }
}
      

    .bodyTitle{
        @include headerFont;
        font-size:1.35em!important;
        color:$primary;
    }
    #videoHolder{
        position:relative;
        padding-bottom:75%;
        /* padding-bottom:56.25%; */
        padding-top:0;
        height:0;
        /* background:url(/images/temp/video-loader.gif) 50% 50% no-repeat; */
        iframe{
            position:absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
        }
    }
}
.videoSlide{
    .slideTip{
        margin: 25px 0 30px 0;
        width:100%!important;
    }
}


.quizSlideMedia, .videoSlideMedia {
   margin-left: 1.5%;
    top: 100px;
    width: 48%;
    overflow:hidden;
}

@media all and (max-width: $lg){
    .slide{
        h1{
            font-size: 1.7em;
        }
    }
}

@media all and (max-width: $xs + 100){
    #inner-content{
        .logoWrapper{  
            &.inside{
                width: 100%;
                height: 70px;
                background: url('../images/StJohns-Logo-H.png') no-repeat center center / auto 50px $primary;
                padding: 5px 0px;
                right: auto;
                left: 0px;
                position: fixed;
                &.top{
                    top: 0px;
                }
            }
            img{
                display: none;
            }
        }
        .slideHolder{
            top: 90px;
        }
        
    }
    .slide{
        
        padding: 60px 45px 40px 45px !important;
        h1{
            margin: 0px;
            color: $secondary;
            position: absolute;
            font-size: 1.3em;
            top: 15px;
            color: $secondary;
            font-weight: 600;
            width: 90% !important;
        }
        .inlineHeading{
            font-size: 1.3em;
        }
        .slideSide{
            float: none;
            margin: 0 auto;
            width: 100%;
            padding-left: 0px;
        }
        .videoCopy, .textCopy {
            float: none !important;
            width: 100%;
            position: static !important;
            padding-top: 20px !important;
        }
    }
    
}
@media all and (max-width: $tn){
    .slide {
            padding-top: 20px !important;
        h1{
            font-size: 1em;
        }
    }
}

@media all and (max-width: 400px){
    
}