footer{
	a{
		@include button;
		display:block;
		text-transform:uppercase;
		text-decoration:none;
		&:hover{
			color:$light!important;
		}
	}
}