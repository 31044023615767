.arrowNav{
    position:fixed;
    height: 0 !important;
    z-index: 3;
    @include boxShadow($blur:0,$spread:0);
    @media all and (max-width:$xs + 100){
        bottom: 0px;
        left:0;
        height: 50px!important;
        width:100%;
        background:$secondary;
    }
    div{
        background:$primary;
        position: fixed;
        top:35%;
        width:auto;
        height:auto;
        cursor:pointer;
        padding:60px;
        overflow:hidden;
        @include rounded(50%);
        @include transition;
        &:hover{
            background:$secondary;
        }
        &.disabled{
            cursor:not-allowed;
            background:transparent;
            span{
                @include opacity(35,.35);
                @media all and (max-width: $xs + 100){
                    @include opacity(100,1);
                }
            }
        }
        span{
            font-size:2.75em;
            color:$light;
            @include transition;
        }
        b{
            position: absolute;
            top:0;
            left:0;
            width:0;
            height:100%;
            background:$primary;
            z-index:-1;
        }
    }
    .menuLink{
        display:none;
        @media all and (max-width: $xs + 100){
            display:block;
            @include headerFont($weight:600);
            position: relative;
            color: $accent;
            width: 100%;
            text-align: center;
            height: 100%;
            top: auto;
            color:$accent;
            cursor:pointer;
            background:none;
            padding-right:0;
            background:none;
            background-size: 7px;
            padding: 15px 0 0 0;
            font-size: 1.125em;
        }
    }
}
#arrowRight{
    right:-60px;
    span{
        float:none;
        &:before{
            right:69px;
            top:40px;
            position: absolute;
        }
    }
}
#arrowLeft{
    left:-60px;
    span{
        float:none;
        &:before{
            left:69px;
            top:40px;
            position: absolute;
        }
    }
}

@media all and (max-width: $xs + 100){
    .arrowNav {
        div{
            padding: 5px 20px;
            position: absolute;
            top: 4px;
            @include rounded(0px);
            span{
                height: 30px;
                width: 30px;
                display: block;
                font-size: 2em;
                &:before{
                    position: static !important;
                }
            }
            &#arrowLeft{
                left: 5px;
            }
            &#arrowRight{
                right: 5px;
            }
        }
    }
}
