@font-face {
    font-family: "st-johns";
    src: url("/fonts/st-johns.eot");
    src: url("/fonts/st-johns.eot?#iefix") format("embedded-opentype"), url("/fonts/st-johns.woff") format("woff"), url("/fonts/st-johns.ttf") format("truetype"), url("/fonts/st-johns.svg#st-johns") format("svg");
    font-weight: normal;
    font-style: normal
}

[data-icon]:before {
    font-family: "st-johns" !important;
    content: attr(data-icon);
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

[class^="icon-"]:before, [class*=" icon-"]:before {
    font-family: "st-johns" !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.icon-menu:before {
    content: "a"
}

.icon-close:before {
    content: "b"
}

.icon-arrow-right:before {
    content: "c"
}

.icon-arrow-left:before {
    content: "d"
}

.icon-bubble:before {
    content: "f"
}

.icon-home:before {
    content: "e"
}

.icon-info:before {
    content: "g"
}

.icon-check:before {
    content: "h"
}

.icon-link:before {
    content: "i"
}

.icon-unchecked:before {
    content: "j"
}

.icon-checked:before {
    content: "k"
}

.icon-lock:before {
    content: "l"
}

.icon-user:before {
    content: "m"
}
