.accordionButtonWrapper{
    @include clearfix;
    margin-bottom:0!important;
    &.open{
        .glyphicon-chevron-up{
            display:block;
        }
        .glyphicon-chevron-down{
            display:none;
        }
    }
    .glyphicon{
        display:block;
        padding:5px;
        margin:13px;
        color:$secondary;
        float:left;
    }
    .glyphicon-chevron-up{
        display:none;
    }
}
.accordionButton{
    @include headerFont;
    background:$primary;
    display:block;
    padding:10px 15px;
    color:$light;
    font-size:1.25em;
    cursor:pointer;
    &:hover{
        background:lighten($primary,5%);
    }
}
.accordionWrapper{
    display:none;
    padding: 15px;
    margin: 0 0 20px 0;
    background: $secondary;
}