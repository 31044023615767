.ie8{
    #backgroundImg{
        div{
            background-position: 50% 50%;
        }
    }
    .adaBtn{
        display: none;
    }
    
    .quizButtons {
        li{
            border:2px solid $primary;
            &.picked{
                border:2px solid $wrong;
            }
            &.picked.correct{
                border:2px solid $correct
            }
        }
    }
    
    .questionBlock{
		margin:0!important;
		&:hover{
			margin:0!important;
		}
		border-bottom:2px solid lighten($dark,45%);
		&.error{
			background:lighten($accent,30%);
		}
	}
}