@font-face {
    font-family: 'frutiger_linotyperegular';
    src: url('../fonts/frutiger-webfont.eot');
    src: url('../fonts/frutiger-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/frutiger-webfont.woff') format('woff'),
         url('../fonts/frutiger-webfont.ttf') format('truetype'),
         url('../fonts/frutiger-webfont.svg#frutiger_linotyperegular') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'frutiger_linotypeitalic';
    src: url('../fonts/frutit-webfont.eot');
    src: url('../fonts/frutit-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/frutit-webfont.woff') format('woff'),
         url('../fonts/frutit-webfont.ttf') format('truetype'),
         url('../fonts/frutit-webfont.svg#frutiger_linotypeitalic') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'frutiger_linotypebold_italic';
    src: url('../fonts/frutz-webfont.eot');
    src: url('../fonts/frutz-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/frutz-webfont.woff') format('woff'),
         url('../fonts/frutz-webfont.ttf') format('truetype'),
         url('../fonts/rutz-webfont.svg#frutiger_linotypebold_italic') format('svg');
    font-weight: normal;
    font-style: normal;

}