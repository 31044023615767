/*-----ADA Link---------------*/
.adaVersion {
    clear:both;
    float:right;
    a {
        color:$primary;
        font-size:1.125em;
        text-align:right;
        letter-spacing:0px;
        text-decoration:none;
        display:block;
        margin-top:5px;
        padding:5px 10px;
        @include transparentBG($color:$light, $opacity:0.85);
        &:hover{
            @include transparentBG($color:$primary, $opacity:0.85);
            color:$light;
        }
    }
    p {
        color:#fff;
        padding:5px 0;
        color:#fff;
        font-family: 'Open Sans',san-serif;
        float:right;   
    }	
    br{display:none;}
}


