nav{
    position: absolute;
    bottom: 40px;
    left: 1.25%;
    width:97.5%;
    @include transparentBG($secondary,0.90);
    @include boxShadow($blur:5px);
    @include transition($speed:.75s);
    &.top{
        height:85px;
        bottom:41px;
        #main-nav{
            display:none;
        }
    }
}
#main-nav{
    list-style:none;
    @include zeroMargPad;
    padding-left:267px;
    @media all and (max-width: $md){
        padding-left: 200px;
    }
    .button{
        width:12.5%;
        float:left;
        position: relative;
        margin-top: 15px;
        &.active{
            a{
                h2{
                    color:$light;
                    .border{
                        border:2px solid $primary;
                        display:block;
                        width:90%;
                        height:85%;
                        margin: 0;
                        position: absolute;
                        top:7.5%;
                        left:5%;
                    }
                }
                &:hover{
                    h2{
                        color:$light;
                        .border{
                            background:$primary;
                            z-index:0;
                            @include transition($speed:.5s);
                        }
                        .title{
                            position: relative;
                            z-index:1;
                        }
                    }
                }
            }
        }
        &.completed{
            a{
                padding-top: 45px;
                h2{
                    color:$light;
                    .border{
                        border:2px solid $primary;
                        display:block;
                        width:100%;
                        height:0;
                        margin-bottom: 15px;
                        @include transition($speed:.5s);
                    }
                }
                &:hover{
                    /* h2{
                        color:$primary;
                    } */
                }
            }
            
        }
        a{
            display:block;
            padding:60px 10px;
            text-decoration:none;
            text-align:center;
            @include transition($speed:.5s);
            h2{
                font-size:1em;
                text-transform:uppercase;
                color:#898b8e;
                text-align:center;
                display:inline-block;
                font-family: $primaryFont;
                @include transition;
                @media all and (max-width: $md){
                    font-size:1em;
                }
            }
            .border{
                display:none;
                @include transition($speed:.5s);
            }
            &:hover{
                h2{
                    color:$light;
                }
            }
        }
    }
}
.navControls{
    position: absolute;
    left: 50%;
    top: 65%;
    margin: 0 0 0 -250px;
    .left,.right{
        position: absolute;
        top:40%;
        color:$light;
        font-size:4.5em;
        cursor: pointer;
        width:auto!important;
        height:auto!important;
        float:none!important;
        @include textShadow($x:0,$y:0,$blur:10px,$color:rgba(0,0,0,0.60));
        &:before{
            font-weight:bold!important;
        }
        &:hover{
            color:$accent;
        }
    }
    .left{
        left:0;
    }
    .right{
        right:0;
    }
    .dots{
        list-style:none;
        margin: 60px auto 0 auto;
        padding: 0;
        @include clearfix;
        .dot{
            padding:6px;
            background:$light;
            margin:0 6px;
            float:left;
            cursor: pointer;
            border:2px solid $light;
            @include boxShadow($blur:5px,$spread:2px,$color:rgba(0,0,0,0.40));
            @include transition;
            &.current{
                background:$primary;
                border:2px solid $accent;
            }
            &:hover{
                background:$accent;
            }
        }
    }
}
#offcanvas-nav{
    position: fixed;
    background: $primary;
    height: 100%;
    padding: 20px 20px 120px 20px;
    left: -500px;
    top: 0;
    overflow-y:auto;
    @include transition;
    display:none;
    list-style:none;
    z-index:2;
    &.inSection{
        li{
            display:none;
        }
        .homeButton{
            display:block;
        }
    }
    li{
        @include zeroMargPad;
        a{
            display:block;
            padding:8px 10px;
            margin:5px 0;
            &:hover{
                text-decoration:none;
                h2{
                    color:$secondary;
                }
            }
            h2{
                color:$light;
                text-transform:uppercase;
                font-size:1.5em;
            }
        }
        &.active{
            border:2px solid $accent;
        }
    }
    .homeButton{
        display:none;
        padding:8px 10px;
        margin:5px 0;
        cursor:pointer;
        &:hover{
            text-decoration:none;
            h2{
                color:$secondary;
            }
        }
        h2{
            color:$light;
            text-transform:uppercase;
            font-size:1.5em;
        }
    }
    .accountInfo{
        display:block;
        border-top: 2px solid $secondary;
        width:100%;
        padding:20px 0;
        margin:20px 0 0 0;
        font-size: 1em;
        color:$secondary;
        font-family:$primaryFont;
        .logout{
            display:inline-block;
            text-align: center;
            background:$secondary;
            color:$primary;
            margin:10px 0 0 0;
            text-decoration:none;
            padding: 5px 10px;
            font-size: .9em;
            &:hover{
                background:darken($secondary,10%);
            }
        }
    }
}
#offcanvasCover{
    display:none;
    position: fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    @include transparentBG($basic,0.75);
    z-index:2;
}


@media all and (max-width: $sm + 75) { //1066
    #main-nav{
        padding-left: 0px;
    }
}
@media all and (max-width: $xs + 100) { //867
    nav{
        position: static;
        margin: 0 auto;
        //@include transparentBG($secondary,0.75);
        background: transparent;
        padding-bottom: 25px;
    }
    #main-nav{
        padding-top: 75px;
        .button{
            margin-top: 0px;
            width: 100%;
            float: none;
            @include transparentBG($secondary,0.85);
//            margin-bottom: 5px;
            border-bottom: 2px solid black;
            a{
                padding: 20px 10px;
            }
        }
    }
    
}


