.selfDir {
    list-style: none;
    float: left;
    clear: both;
    margin: 0;
    padding: 0
}

.selfDir:after {
    content: " ";
    visibility: hidden;
    display: block;
    height: 0;
    clear: both
}

.selfDir .selfDirBtn {
    padding: 5px 0
}

.selfDir .selfDirBtn a {
    font-size: 1.35em;
    color: #cf102d;
    display: inline-block;
    text-decoration: none;
    padding: 10px;
    -webkit-transition: all .2s ease;
    -moz-transition: all .2s ease;
    -o-transition: all .2s ease;
    transition: all .2s ease
}

.selfDir .selfDirBtn a:hover {
    background: #cf102d;
    color: #fff !important
}

.selfDir .selfDirBtn a:hover:after {
    color: #fff
}

.selfDir .selfDirBtn a:after {
    -webkit-transition: all .2s ease;
    -moz-transition: all .2s ease;
    -o-transition: all .2s ease;
    transition: all .2s ease;
    font-family: 'st-johns';
    content: 'c';
    float: right;
    padding-left: 5px;
    color: #cf102d
}
