/*---TEXT AND FONT---*/
@mixin bodyCopy {
    color: $basic;
    font-family: $basicFont;
}
@mixin headerFont($weight:500, $color: $primary) {
    color: $color;
    font-family: $primaryFont;
    font-weight: $weight;
}

@mixin textShadow ($x: 0px, $y: 1px, $blur: 0px, $color: rgba(0,0,0,.75)){
     -ms-filter: "progid:DXImageTransform.Microsoft.Shadow(Strength=$blur, Direction=0, Color=$color)";/*IE 8*/
    text-shadow: $x $y $blur $color;
    filter: progid:DXImageTransform.Microsoft.Shadow(Strength=$blur, Direction=135, Color=$color); /*IE 5.5-7*/
}


/*---BACKGROUND---*/
@mixin backgroundCover{
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position:50% 50%;
    &.topLeft{
        background-position:0 0;
    }
    &.topCenter{
        background-position:50% 0;
    }
    &.topRight{
        background-position:100% 0;
    }
    &.bottomLeft{
        background-position:100% 0;
    }
    &.bottomCenter{
        background-position:100% 50%;
    }
    &.bottomRight{
        background-position:100% 100%;
    }
}
@mixin linear-gradient($from, $to) {
    /* Old browsers */
    background: $to;
    /* FIREFOX*/
    background: -moz-linear-gradient($from, $to);
   /* Chrome,Safari4+ */
    background: -webkit-gradient(linear,left top, left bottom, color-stop(0, $from), color-stop(1, $to));
   /* Chrome10+,Safari5.1+*/
    background: -webkit-linear-gradient($from, $to);
   /* Opera 11.10+ */
    background: -o-linear-gradient($from, $to);
    /* IE10+ */
    background: -ms-linear-gradient($from, $to);
   /* W3C */
    background: linear-gradient($from, $to);
    /* IE6-9 */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$from', endColorstr='$to',GradientType=0 );
}
@mixin transparentBG($color, $opacity){
    background-color:$color;
    background:rgba($color,$opacity);
}

@mixin fillSpace{
    height: 100%;
    width: 100%;
}


/*---BOX---*/
@mixin rounded($radius){
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    border-radius: $radius;
}
@mixin boxShadow($x: 0px, $y: 0px, $blur: 2px, $spread: 2px, $color: rgba(0,0,0,.2)){
    -webkit-box-shadow: $x $y $blur $spread $color;
    -moz-box-shadow: $x $y $blur $spread $color;
    box-shadow: $x $y $blur $spread $color;
}
@mixin shadow-inset($x: 0px, $y: 1px, $blur: 3px, $spread: 0px, $color: rgba(0,0,0,.2)){
    -webkit-box-shadow: inset $x $y $blur $spread $color;
    -moz-box-shadow: inset $x $y $blur $spread $color;
    box-shadow: inset $x $y $blur $spread $color;
}
@mixin border($color: $light, $size: 1px)  {
    border: $size solid $color;
}



/*---POSITIONING---*/
@mixin absolute {
    position: absolute;
    top: 0;
    left: 0;
}
@mixin fixed {
    position: fixed;
    top: 0;
    left: 0;
}
@mixin zeroMargPad {
    margin: 0;
    padding: 0;
}



/*---CONTENT---*/
@mixin pseudoContent {
    content: " ";
    height: 100%;
    width: 100%;
    display: block;
}



/*---BORDER ARROWS---*/
@mixin rightArrow($size: 8px, $sizeTB: $size, $color: $light, $top: auto, $right: auto){
    width: 0;
    height: 0;
    border-top: $sizeTB solid transparent;
    border-bottom: $sizeTB solid transparent;
    border-left: $size solid $color;
    border-right:0;
    position: absolute;
    top: $top;
    right:$right;
}
@mixin leftArrow($size: 8px, $sizeTB: $size, $color: $light, $top: auto, $left: auto){
    width: 0;
    height: 0;
    border-top: $sizeTB solid transparent;
    border-bottom: $sizeTB solid transparent;
    border-left:0;
    border-right: $size solid $color;
    position: absolute;
    top: $top;
    left: $left;
}
@mixin topArrow($size: 8px, $sizeLR: $size, $color: $light, $top: auto, $left: auto){
    width: 0;
    height: 0;
    border-left: $sizeLR solid transparent;
    border-right: $sizeLR solid transparent;
    border-bottom: $size solid $color;
    border-top:0;
    position: absolute;
    top: $top;
    left: $left;
}
@mixin bottomArrow($size: 8px, $sizeLR: $size, $color: $light, $top: auto, $left: auto){
    width: 0;
    height: 0;
    border-left: $sizeLR solid transparent;
    border-right: $sizeLR solid transparent;
    border-top: $size solid $color;
    border-bottom:0;
    position: absolute;
    top: $top;
    left: $left;
}




/*---TRANSFORMS---*/
@mixin rotate( $deg: -5deg){
    -webkit-transform: rotate($deg);
    -moz-transform: rotate($deg);
    -o-transform: rotate($deg);
    transform: rotate($deg);
}

@mixin transform($type, $value){
    -webkit-transform: $type($value);
    -moz-transform:  $type($value);
    -o-transform:  $type($value);
    transform:  $type($value);
}

/*---FILTERS---*/
@mixin grayscale($value: 1){
    -ms-filter: grayscale($value);
    -webkit-filter: grayscale($value);
    -moz-filter: grayscale($value);
    -o-filter: grayscale($value);
    filter: grayscale($value);
}

@mixin blur($value){
    -ms-filter: blur($value);
    -webkit-filter: blur($value);
    -moz-filter: blur($value);
    -o-filter: blur($value);
    filter: blur($value);
}

@mixin brightness($value){
    -ms-filter: brightness($value);
    -webkit-filter:brightness($value);
    -moz-filter: brightness($value);
    -o-filter:brightness($value);
    filter: brightness($value);
}

@mixin filters($gs: 0, $blur: 0px, $bright: 0%, $hue: 360deg){
    -ms-filter: grayscale($gs) blur($blur) brightness($bright) hue-rotate($hue);
    -webkit-filter: grayscale($gs) blur($blur) brightness($bright) hue-rotate($hue);
    -moz-filter: grayscale($gs) blur($blur) brightness($bright) hue-rotate($hue);
    -o-filter: grayscale($gs) blur($blur) brightness($bright) hue-rotate($hue);
    filter: grayscale($gs) blur($blur) brightness($bright) hue-rotate($hue);
}

/*---TRANSITIONS---*/
@mixin transition($property: all, $speed: .2s){
    -webkit-transition: $property $speed ease;
    -moz-transition: $property $speed ease;
    -o-transition: $property $speed ease;
        transition: $property $speed ease;
}




/*---UTILITY---*/
@mixin borderBox {
     -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
@mixin contentBox {
     -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}
@mixin clearfix{
    &:after{
        content: " "; /* Older browser do not support empty content */
        visibility: hidden;
        display: block;
        height: 0;
        clear: both;
    }
}
@mixin opacity($oldBrowser, $newBrowser){
    /* Theoretically for IE 8 & 9 (more valid) */
    /* ...but not required as filter works too */
    /* should come BEFORE filter */
    -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=$oldBrowser)";

    /* This works in IE 8 & 9 too */
    /* ... but also 5, 6, 7 */
    filter: alpha(opacity=$oldBrowser);

    /* Older than Firefox 0.9 */
    -moz-opacity:$newBrowser;

    /* Safari 1.x (pre WebKit!) */
    -khtml-opacity: $newBrowser;

    /* Modern!
    /* Firefox 0.9+, Safari 2?, Chrome any?
    /* Opera 9+, IE 9+ */
    opacity: $newBrowser;
}


/*---Images---*/
@mixin rspImage{
    display: block;
    max-width: 100%;
    height: auto;
}


/*---Video---*/

@mixin rspVideo($ratio: 75%, $gifPath: url(../images/video-loader.gif)){
    position: relative;
    padding-bottom: $ratio;
    padding-top: 0;
    height: 0;
    background: $gifPath 50% 50% no-repeat;

        iframe{
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
}

@mixin videoBG($bgImg){
    width: 100%;
    @include absolute;
    overflow: hidden;
    video{
        position: fixed;
        right: 0;
        bottom: 0;
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        z-index: -100;
        background-image: $bgImg;
        @include backgroundCover
    }
}


/**Forms**/

@mixin placeholder($color: lighten($basic, 60%), $style: italic, $font: $basicFont, $size: 1em){
    &::-webkit-input-placeholder {
       color: $color;
       font:{
        style: $style;
        family: $font;
        size: $size;
        }
    }
    &:-moz-placeholder { /* Firefox 18- */
        color: $color;
       font:{
        style: $style;
        family: $font;
        size: $size;
        }
    }
    &::-moz-placeholder {  /* Firefox 19+ */
        color: $color;
       font:{
        style: $style;
        family: $font;
        size: $size;
        }
    }
    &:-ms-input-placeholder {
        color: $color;
       font:{
        style: $style;
        family: $font;
        size: $size;
        }
    }
}

@mixin flexJustify($dir:row, $justify: space-between){//alternate would be space-around
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: $dir;
}

@mixin flexCenter($dir: row){
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: $dir;
}

@mixin flexEnd($dir: row){
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: $dir;
}

@mixin flexStart($dir: row){
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: $dir;
}
@mixin scrollbar($width: 5px, $color: $secondary, $bgColor:#F5F5F5 ){
  &::-webkit-scrollbar-track{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
     background-color: $bgColor;
  }
  &::-webkit-scrollbar{
     width: $width;
     height: $width;
     background-color: $bgColor;
   }
   &::-webkit-scrollbar-thumb{
      background-color: $color;
   }
}


@mixin vertCenter($percent: 50%, $trans: 50%){
  position: relative;
  top: $percent;
  transform: translateY(-$trans);
  -webkit-transform: translateY(-$trans);
}

@mixin horzCenter($percent: 50%, $trans: 50%){
  position: relative;
  left: $percent;
  transform: translateX(-$trans);
  -webkit-transform: translateX(-$trans);
}

@mixin vertHorzCenter{
  @include vertCenter;
  @include horzCenter;
  transform: translate(-50%,-50%);
}
