.slide.quizSlide{
    .videoWrapper{
        margin:0;
    }
    h2{
        padding:0;
        font-size:1.75em;
    }
    h1{
        margin:-86px 0 60px 0;
    }
}
.quizCopy {
    
}
    
.quizButtons {
    @include zeroMargPad;
    list-style:none;
    margin-top: 15px; 
    width: auto;
    @include headerFont($weight:600);
    li{
        background: $secondary;
        @include clearfix;
        padding:5px; 
        cursor: $cursor;
        color: $light;
        margin-bottom:10px; 
        width: 100%;
        display: table;
        @include transition;
        &:hover{        
            @include boxShadow;
            background:$light;
            margin-left: -10px;
            margin-right: -10px;
            color:$basic;
            .letter{
                color:$basic!important;
            }
        }
    }
     p {  
        float: right;
        padding: 13px;
        margin: 0px;
        font-size: 1em;
        height: 100%;
        width: 80%;
        box-sizing: border-box;
        text-align:right;
        vertical-align:middle;
        display:table-cell;
        @media all and (max-width: $tn){
            font-size:1em;
        }
        } 
    span.letter{
        font-family: $primaryFont;
        font-weight:400;
        display: table-cell;
        vertical-align:middle;
        float: left;
        width: 14%;
        font-size: 2em;
        box-sizing: border-box;
        text-align: center;
        color: $light;
    }
    .picked{
        background:$wrong;
        color: $light;
        &:hover{
            background:$wrong;
            color:$light;
            span.letter{
                color:$light!important; 
            }
        }
        span.letter {
            background: $wrong;
            color:$light;
        }
    }
    li.correct.picked {
        color: $light;
        background:$correct;
        &:hover{
            background:$correct;
            color:$light;
            span.letter{
                color:$light!important; 
            }
        }
        span.letter {
           background: $correct;
            
        }

       
    }
    
}
.answersWrapper{
    @include clearfix;
    color: $basic;
    font-size:1.35em;
    padding: 20px 35px 20px 35px;
    @media all and (max-width: $tn){
        padding:20px 10px;
        margin-bottom:75px;
    }
}
.quizContent {
     color:$light;
     padding:0;
    p {
        line-height:1.25em;
        padding:20px;
        width: auto;
        font-size:.9em;
        position:relative;
        &.question {
            padding:20px 0;
            color:$basic;
            font-size:1em;
        }
        &.answerCorrect{
            display:none;
            background:$correct;
            font-size:1em;
        }
    }
    p.answerWrong{
        background-color: $wrong;
        display:none;
        background:$wrong;
        font-size:1em;
    }
    .glyphicon{
        font-size: 2.75em;
        padding-right: 10px;
        top: 21px;
        left: -3px;
        position: absolute;
    }
}

/*---Fixes for IE---*/
