#homeScreen{
    width:100%;
    height:100%;
    position: fixed;
    top:0;
    left:0;
    overflow-x:hidden;
    z-index:2;
   .logoWrapper{
        position: absolute;
        bottom:0;
        left:45px;
        background:$primary;
        padding:40px 40px;
        text-align:center;
        z-index:1;
        @media all and (max-width: $md){
            padding: 40px 20px;
            left: 25px;
        }
        &.top{
            width:170px;
            height:170px;
            padding:20px 25px;
            left:85%;
            bottom:30px;
        }
    }
    .logo{
        
    } 
    .topicHeading{
        position: absolute;
        top:0;
        left:310px;
        height:93px;
        display:none;
        background:$primary;
        h1{
            width:100%;
            padding: 25px 80px;
            margin:0;
            text-align:left;
            text-transform:uppercase;
            color:$light;
            position: absolute;
            top:0;
            right:0;
            z-index:2;
        }
        .progress{
            @include rounded(0);
            background:$secondary;
            width:0;
            height:100%;
            position: absolute;
            top:0;
            left:0;
            span{
                position: absolute;
                bottom: 5px;
                right: 10px;
                left: auto!important;
                top: auto!important;
                display: none;
                text-align: right;
                font-size: 1em;
                color: $light;
                background: transparent;
                width:auto!important;
                height:auto!important;
            }
        }
    }
    .navFrame{
        position: absolute;
        top:45%;
        border:6px solid $accent;
        display:none;
        @include boxShadow($blur:8px,$spread:3px,$color:rgba(0,0,0,0.20));
    }
}

@media all and (max-width: $sm + 75) { //1066
    #homeScreen{
        .logoWrapper{
            padding: 22px 22px 25px 20px;
            top: 0px;
            bottom: auto;
            left: 25px;
            .img-responsive{
                display: inline-block;
                max-width: 90%;
            }
        }
    }
}

@media all and (max-width: $xs + 100) { //867
    #backgroundImg{
        z-index: -1;
    }
    #homeScreen{
        position: static;
        .logoWrapper{
            width: 100%;
            background: url('../images/StJohns-Logo-H.png') no-repeat center center / auto 50px $primary;
            padding: 5px 0px;
            left: 0px;
            height: 70px;
            position: fixed;
            .img-responsive{
                display: none;
            }
        }
    }

    
}
