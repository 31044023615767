.popupMessage {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	@include transparentBG($basic,0.80);
	z-index: 9999;

	.message {
		width: 400px;
		height: 250px;
		position: absolute;
		left: 50%;
		top: 200px;
		margin: 0 0 0 -200px;
		background: $secondary;
		border: 4px solid $light;
		@include boxShadow;
	}

	h4 {
		@include headerFont;
		font-size: 3em;
		font-weight: 400;
		text-align: center;
		color: #fff;
		margin: 10px 0;
		text-shadow: 0 0 8px #222222;
		filter: dropshadow(color=#222222, offx=0, offy=0);
	}

	p {
		text-align: center;
		margin: 0 20px;
		color: #fff;
	}

	.continue {
		display: block;
		width: 100px;
		padding: 0;
		margin: 25px auto 0;
		text-transform: uppercase;
		color: $light;
		font-weight: bold;
		cursor: $cursor;

		.glyphicon {
			font-size: 4em;
			text-align: center;
			display: block;
			color: $primary;
		}

		&:hover {
			span {
				color: darken($light,10%);
			}

			span.glyphicon {
				color: darken($primary,10%);
			}
		}
	}

	.btnWrap {
		text-align: center;
	}

	.stayBtn {
		display: inline-block;
		&:before {
			content: 'h';
			font-family: $iconFont;
			color: $primary;
			display: block;
			font-size: 4.1em;
		}
		&:hover {
			.button {
				color: darken($light,10%);
			}

			&:before {
				color: darken($primary,10%);
			}
		}
	}
	.leaveBtn {
		display: inline-block;
		&:before {
			content: 'b';
			font-family: $iconFont;
			color: $primary;
			display: block;
			font-size: 3.8em;
		}
		&:hover {
			.button {
				color: darken($light,10%);
			}

			&:before {
				color: darken($primary,10%);
			}
		}
	}
	.button {
		padding: 5px 10px 5px 10px;
		color: $light;
		margin: 20px;
		font-weight: bold;
		text-transform: uppercase;
	}
}
