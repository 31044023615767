html { //Shows sidebar at all times
    overflow: -moz-scrollbars-vertical;
    overflow-y: scroll;
    overflow-x:hidden;
}

body {
	@include zeroMargPad;
	@include bodyCopy;
    background: $secondary;
}
body:before{
    content:""!important;
}
img{border:0;}

a {color: $secondary;}

h1, h2, h3, h4, h5, h6 {font-family: $primaryFont; margin: 0px;}

.container-fluid{
	padding:0 50px;
	@media all and (max-width: 1370px){

	}
	@media all and (max-width: $sm){

	}
	@media all and (max-width: $xs){

	}
}

.row{
	margin:0!important;
}
#wrapper{
	@include zeroMargPad;
    &.whiteBackground{
    	//@include transparentWhite($opacity:0.85);
    }
}
.logo{
	cursor:pointer;
}
#mainContent{
	height:100%;
	width:100%;
	@media all and (max-width: $md){

	}
	@media all and (max-width: $sm){

	}
	@media all and (max-width: $xs){

	}
	@media all and (max-width: $tn){

	}
}
#gradientLeft{
	width:35px;
	height:100%;
	background-image:url(../images/gradient-left.png);
	background-repeat:repeat-y;
	position: fixed;
	top:0;
	left:0;
}
#gradientRight{
	width:35px;
	height:100%;
	background-image:url(../images/gradient-right.png);
	background-repeat:repeat-y;
	position: fixed;
	top:0;
	right:-4px;
}
.accountInfo{
	display:none;
}

.adaBtn{
//    display: none;
//    opacity: 0;
    position: fixed;
    bottom: 0px;
    right: 1%;
    display: inline-block;
    background: $primary;
    padding: 5px 10px;
    color: $light;
    text-decoration: none;
    z-index: 100;
    font-size: .8em;
    font-weight: 600;
    text-transform: uppercase;
    &:focus, &:hover{
        color: $light;
        background: $secondary;
    }
}

.guestBtn {
  position: fixed;
  bottom: 0px;
  right: 1.25%;
  display: inline-block;
  background: $primary;
  padding: 5px 10px;
  color: $light;
  text-decoration: none;
  z-index: 100;
  font-size: .8em;
  font-weight: 600;
  text-transform: uppercase;
  &:focus, &:hover{
      color: $light;
      background: $secondary;
  }
}

@media (max-width: $xs + 100){
    .adaBtn{display: none;}
}
