#backgroundImg{
	border:1px solid $primary;
	width:100%;
	height:100%;
	position:fixed;
	top:0;
	left:0;
	@include backgroundCover;
	div{
		width:100%;
		height:100%;
		position:fixed;
		top:0;
		left:0;
		@include backgroundCover;
		background-position:100% 0;
        display: none;
	}
}

