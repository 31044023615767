.membership{
  .acntMenuBtn{display: none !important;}
  #backgroundImg{z-index: -1;}
  .adaBtn{display: none !important;}
  .memberGroupWrap{
    .formWrap{
      padding: 0px 35px;
    }
    h2{
      margin-bottom: 15px;
      text-align: center;
      color:$primary;
      font-size: 2.5em;
    }
    h3{
      text-align: center;
      font-weight: 700;
      padding-bottom:5px;
    }
    .instruction{text-align: center;}
    .inner{
      background: $light;
    }
    .answers{

    }
    .fieldWrapper{
      float: none !important;
      input,select{
        width: auto !important;
      }
      label{
        
      }
      .group{
        margin-bottom: 5px;
        font-size: 1.25em;
      }
      // width: 100% !important;
      // float: none !important;
    }
    .Submit{
      position: static !important;
      padding-bottom: 15px;
      text-align: center;
    }
  }
}

ul.sections {
  li.avatar {
    a {
      background: url('../images/admin.png') no-repeat center center !important;
    }

    #avatar-img {
      display: none !important;
      opacity: 0 !important;
    }
  }
}

.studentMsg {
  @include fixed;
  background: $light;
  width: 100%;
  text-align: center;
  padding: 20px 25px 15px;
  @include borderBox;
  @include transition($speed: .75s);
  top: -125px;

  p {
    font-size: 1.2em;
    color: $primary;
  }

  a {
    text-transform: uppercase;
    color: $secondary;
    font-weight: 600;
    text-decoration: underline;
  }

  &.open {
    top: 0;
  }
}

#membershipForms {
  margin-top: 50px;
  @include transition($speed: .75s);

  &.msgOpen {
    margin-top: 125px;
  }


  .inner {
    display: table;
    max-width: 750px;
    margin: 0 auto;
  }

  .branding {
    display: table-cell;
    width: 30%;
    background: $primary;
    padding: 20px 25px 30px 20px;
    text-align: center;
    @include borderBox;

    img {
      max-width: 100%;
      height: auto;
    }
  }

  .memberContent {
    width: 70%;
    display: table-cell;
    @include borderBox;
    vertical-align: middle;
    background: $light;
    // padding-left: 20px;
    padding: 15px;
  }

  .formWrapper {
    position: relative;
    background: $light;

    form {
      width: 100%;
      @include clearfix;
    }

    &.login {
      padding: 0;
      background: transparent;

      h1 {
        padding-bottom: 10px;
      }

      .fieldWrapper {
        float: none;
        width: 100%;
        padding: 0;

        label {
          display: none;
        }
      }

      .utility {
        position: static;

        .forgotPass {
          background: $secondary;
          padding: 5px 10px;

          &:hover {
            background: $primary;
          }

          a {
            color: $light;
          }
        }
      }
    }

    &.userAccount {
      padding: 30px 15px 35px;

      form {
        max-width: 650px;
        margin: 0 auto;
        @include clearfix;
      }

      .formWrap {
        .utility {
          .forgotPass {
            background: $secondary;
            padding: 5px 10px;

            &:hover {
              background: $primary;
            }

            a {
              color: $light;
            }
          }
        }
      }
    }

    &.password {
      max-width: 650px;
      min-height: 215px;
      padding-top: 40px;

      .fieldWrapper {
        float: none;
        width: 90%;

        label {
          display: none;
        }
      }

      .Submit {
        margin: 0 auto;
        text-align: center;
        padding-right: 0;
      }
    }

    h1 {
      font-family: $primaryFont;
      font-weight: 400;
      font-size: 30px;
      padding-bottom: 20px;
      text-align: center;
      color: $primary;
    }

    p {
      font-family: $primaryFont;
      font-weight: 600;
      font-size: 1em;
      text-align: center;
    }

    button {
      background: $secondary;
      text-decoration: none;
      font-size: 1em;
      font-family: $primaryFont;
      font-weight: 400;
      display: inline-block;
      padding: 7px 45px;
      color: $light;
      text-transform: uppercase;
      margin-top: 10px;
      border: 0;
      box-shadow: none;
      @include rounded(0px);

      &:hover {
        color: $light;
        background: $light;
      }
    }
  }

  .formArea {
    // background: $primary;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding: 25px 15px 15px;

    .studentLoginBtn {
      @include button;
      padding: 10px 15px;
      font-family: $primaryFont;
      font-size: 1em;
      font-weight: 600;
      margin-right: 15px;
    }

    .guestLoginBtn {
      @include button;
      background: $secondary;
      padding: 10px 15px;
      font-family: $primaryFont;
      font-size: 1em;
      font-weight: 600;
    }

    p {
      margin-bottom: 20px;
      margin-top: 0;
      color: $secondary;
    }
  }

  .newStudentWrapper {
    @include borderBox;
    flex-direction: column;
    background: $secondary;
    color: $light;
    text-align: center;
    border: 2px solid $light;
    padding: 15px;

    p {
      color: $light;
    }

    h1 {
      color: $light;
      font-size: 1.5em;
    }

    .account {
      margin-top: 15px;
    }
  }

  .account {
    position: relative;

    a {
      @include button;
      margin-top: 10px;
      padding: 10px 15px;
      text-transform: uppercase;
      font-size: 1em;
      font-weight: 400;

      &:before {
        @include pseudoContent;
        @include absolute;
        //content: 'm';
        @include iconFont;
        left: 10px;
        text-align: left;
        top: 6px;
        font-size: 2.2em;
        font-weight: normal;
        text-transform: none;
      }
    }
  }

  .returningStudentWrapper {
    flex-direction: column;
    @include borderBox;
    background: $light;
    color: $primary;
    padding: 25px 40px;
  }

  .fieldWrapper {
    @include clearfix;
    width: 50%;
    margin: 0 auto 10px;
    float: left;
    @include borderBox;
    padding: 0 15px;

    &.selectField {
      width: 100%;

      label {
        display: block;
      }

      select {}
    }

    label {
      color: $secondary;
    }

    input,
    select {
      margin-bottom: 5px;
      padding: 5px 10px;
      font-size: 1.2em;
      font-family: $primaryFont;
      box-shadow: none;
      width: 100%;
      background: $grey;
      border: none;

      &.input-validation-error {
        border: 1px solid $primary !important;
      }
    }
  }

  .Submit {
    margin-top: 20px;
    position: absolute;
    bottom: -50px;
    right: 0;

    button {
      background: $primary;
      @include rounded(0px);
      font-family: $basicFont;
      color: $light;
      text-transform: uppercase;
      border: 1px solid $primary;
      padding: 7px 17px;
      @include transition();

      &:hover {
        color: $light;
        background: darken($primary, 5%);
      }
    }
  }

  .utility {
    @include absolute;
    top: auto;
    bottom: 10px;
    left: 25px;

    a {
      color: $secondary;
      font-size: 0.8em;
      text-decoration: underline !important;
      // margin-right: 10px;
    }
  }

  .error {
    color: lighten($wrong, 20%);
    font-weight: bold;
    font-weight: 600;
    font-size: 0.85em;
    display: block;
    margin-top: -1px;
  }

  


  @media (max-width: $sm) {
    .formArea {
      padding: 0;
    }

    .newStudentWrapper {
      display: block;
      width: 100%;

      h1 {
        margin-top: 0;
        padding-top: 20px;
      }
    }

    .returningStudentWrapper {
      width: 100%;
      display: block;
      padding: 25px;

      .fieldWrapper {
        width: 80%;
        margin: 0 auto;
      }
    }

    .account {
      padding-bottom: 20px;

      a {
        margin-top: 0;
      }
    }

    .Submit {
      width: 80%;
      text-align: center;

      button {
        width: 100%;
        font-size: 1.2em;
      }
    }

    // .forgotPass {
    //   text-align: center;
    //   padding-top: 5px;
    // }
    .forgotPass {
      background: $secondary;
      padding: 5px 10px;

      &:hover {
        background: $primary;
      }

      a {
        color: $light;
      }
    }
  }
  @media (max-width: $xs) {

      .inner {
        display: flex;
        position: relative;
        flex-direction: column;
        align-items: center;
      }

      .branding {
        width: 100%;
      }
      .memberContent {
        width: 100%;
      }

    .formWrap {
      //        margin-top: 110px;

      h1 {
        font-size: 1.5em;
      }
    }

    .formArea {
      p {
        display: none;
      }
    }
  }
  @media (max-width: $tn) {
    .returningStudentWrapper {
      padding: 15px;

      .fieldWrapper {
        width: 95%;
      }
    }
  }
}
