.progress{
	overflow:hidden;
	margin-bottom:0;
	@include rounded($radius:0);
	position: absolute;
	top: 24px;
	background: $primary;
	color:$light;
	width:auto;
	height: 50px;
	left: 1.25%;
	display:none;
	.percentage{
		position: absolute;
		right: 0;
		top: 0;
		background: darkred;
		padding: 17px;
		@include transition;
		font-size:.85em;
		&.drop{
			padding: 33px 16px 16px 16px;
		}
	}
}
.progressSectionTitle{
	@include opacity(0,0);
	font-family:$primaryFont;
	display: block;
	font-size: 1.75em;
	text-transform: uppercase;
	color: $light;
	z-index: 3;
	position: absolute;
	top: 30px;
	left: 7%;
	@include transition($speed:.5s);
	&.open{
		@include opacity(100,1);
	}
}

@media all and (max-width: $xs + 100){
    .progressSectionTitle{
        top: 75px;
        font-size: 1em;
    }
    .progress{
        top: 74px;
        background: $secondary;
        height: 25px;
        .percentage{
            padding: 5px 10px;
            @include transparentBG($light, .2); 
        }
    }
}