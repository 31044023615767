.spinner{
	display:none;
	position: fixed!important;
	left: 50%!important;
	top: 30%!important;
	div{
		div{
			background:$primary!important;
		}
	}
}